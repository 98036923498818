
.info {
  width: 756px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 24px;
  .infoTop {
    // background: red;
    width: 756px;
    height: 158px;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    // justify-content: start;
  }
  .logo {
    width: 184px;
    height: 110px;
    border-radius: 6px;
    // margin-right: 20px;
    // padding: 24px 30px 24px 542px;
  }
  .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
  .intros {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    .name {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: bold;
      color: #101010;
      line-height: 36px;
    }
    .labelGroup {
      margin-top: 20px;
      width: 100%;
      display: flex;
      .item {
        margin-right: 8px;
        list-style: none;
        border-radius: 8px;
        border: 1px solid #3e9cff;
        padding: 7px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #3e9cff;
      }
    }
    .intro {
      // background: red;
      width: 100%;
      margin-top: 20px;
      
      > p {
        margin-top: 20px;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #8896A7;
      }
      // .intro-header{
      //   font-size: 12px;
      //   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //   font-weight: 400;
      //   color: #8896A7;
      // }
      span:nth-child(1){
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #8896A7;
      }
      span:nth-child(2){
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #404040;
        padding-left:12px ;
      }
      .stage {
        padding-left:12px ;
      }
    }
  }
  .lineBox {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
  .infomain {
    margin: 20px 0;
   .info-title {
        margin-bottom: 24px;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #101010;
          line-height: 24px;
        }
        .label-value{  
          display: flex;
          flex-direction: row;
          margin-top: 23px;
          .label-item{
            height: 34px;
            padding: 0 16px;
            margin-right: 12px;
            background: #FAFAFA;
            border-radius: 18px;
            border: 1px solid #F1F1F1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #101010;
            text-align: center;
            line-height: 34px;
          }
        }
      }
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }
  }
  .contact {
  margin-top: 42px;
  margin-bottom: 40px;
  .title {
    font-size: 28px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #101010;
  }
  // .name {
  //   margin-top: 20px;
  //   font-weight: 500;
  //   background-color: #2885ff;
  //   padding: 6px;
  //   box-sizing: border-box;
  //   display: inline-block;
  //   border-radius: 2px 8px 8px 8px;
  //   color: #ffffff;
  //   font-size: 14px;  
  //   line-height: 21px;
  //   margin-bottom: 20px;      
  // }
  // .text {
  //   margin-top: 14px;
  //   font-size: 16px;
  //   font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  //   font-weight: 400;
  //   margin-top: 14px;
  // }
  .info-title {
        margin-bottom: 24px;
        margin-top: -24px;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #101010;
          line-height: 24px;
        }
      }
  .info-consulting {
        .tit {
          background-color: #2885ff;
          padding: 6px;
          box-sizing: border-box;
          display: inline-block;
          border-radius: 2px 8px 8px 8px;
          color: #ffffff;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 20px;
        }
        .consulting-item {
          margin-bottom: 20px;
          .label {
            font-size: 12px;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }

          .value {
            font-size: 12px;
            line-height: 18px;
            color: #404040;
          }
        }
      }
}

  
}

::v-deep .locationList{
  position: absolute;
  left: -27px;
}

@media screen and (max-width: 768px) {
}
